<template>
<div>
  <form 
    method="post" 
    class="form" 
    ref="mainForm"
    @submit.prevent="submit"
  >
    <p class="title title--3">
      <span v-if="!innerUser.id">Ajouter un équipier</span>
      <span v-else>Mettre à jour les infos équipier</span>
    </p>

    <fieldset class="form__field">
      <input
        v-model="innerUser.active"
        id="active"
        type="checkbox"
        class="form__input form__input--checkbox"
      />
      <label for="active" class="form__label">
        Utilisateur activé
      </label>
    </fieldset>

    <fieldset class="form__field">
      <input
        v-model="innerUser.leader"
        id="leader"
        type="checkbox"
        class="form__input form__input--checkbox"
      />
      <label for="leader" class="form__label">
        Cycliste leader
      </label>
    </fieldset>
    
    <fieldset class="form__field form__field--two-col">
      <label for="firstname" class="form__label">
        Prénom
      </label>
      <input type="text" id="firstname" placeholder="Prénom" class="form__input" v-model="innerUser.firstname">
    </fieldset>

    <fieldset class="form__field form__field--two-col">
      <label for="lastname" class="form__label">
        Nom
      </label>
      <input type="text" id="lastname" placeholder="Nom" class="form__input" v-model="innerUser.lastname">
    </fieldset>

    <fieldset class="form__field form__field--two-col">
      <label for="tel" class="form__label">
        Téléphone
      </label>
      <input type="text" id="tel" placeholder="Téléphone" class="form__input" v-model="innerUser.tel">
    </fieldset>

    <fieldset class="form__field form__field--two-col">
      <label for="mail" class="form__label">
        Email
      </label>
      <input type="text" id="mail" placeholder="Email" class="form__input" v-model="innerUser.email">
    </fieldset>

    <fieldset class="form__field">
      <label class="form__label">Vélo</label>
    
      <select v-model="innerUser.bicycle" class="form__input form__input--select">
        <option v-for="(bicycle, i) in availableBicycles" :key="i" :value="bicycle">
          {{ bicycle }}
        </option>
      </select>
    </fieldset>

    <fieldset class="form__field">
      <label class="form__label">Permissions</label>

      <ul class="form__button-container">
        <li v-for="(role, i) in innerUser.roles" :key="i">
          {{ role.label }}

          <button 
            class="btn btn--danger btn--small"
            type="button"
            @click="removeElement('roles', role)"
          >
            <i class="fa fa-trash"></i>
          </button>
        </li>
      </ul>
    
      <DataSelectObject
        label="Ajouter une permission"
        :value="innerUser.roles"
        :options="availableRoles"
        @add="addElement('roles', $event)"
        @remove="removeElement('roles', $event)"
      />
    </fieldset>

    <fieldset class="form__field">
      <label class="form__label">Couleur</label>
    
      <select v-model="innerUser.color" class="form__input form__input--select">
        <option v-for="(color, idColor) in colors" :key="idColor" :value="color">
          {{ color.name }}
        </option>
      </select>
    </fieldset>
    
    <button class="btn btn--primary">
      Sauvegarder
    </button>
  </form>

</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { DataSelectObject } from '@/components/Common';

export default {
  components: { DataSelectObject },

  props: {
    user: {
      type: Object,
      default: () => {},
      required: true,
    },
  },

  data() {
    return {
      innerUser: {},
      colors: [
        { name: 'Rouge', value: 'red' },
        { name: 'Bleu', value: 'blue' },
        { name: 'Vert', value: 'green' },
        { name: 'Jaune', value: 'yellow' },
        { name: 'Orange', value: 'orange' },
        { name: 'Mauve', value: 'purple' },
      ],
    };
  },

  computed: {
    ...mapGetters({
      availableRoles: 'users/availableRoles',
      availableBicycles: 'users/availableBicycles',
    }),
  },

  watch: {
    user: {
      immediate: true,
      nested: true,
      handler() {
        this.innerUser = JSON.parse(JSON.stringify(this.user));
      }
    }
  },

  methods: {
    submit(e) {
      e.preventDefault();

      if (this.innerUser.id) return this.$emit('save', this.innerUser);

      this.$emit('create', this.innerUser);
    },

    addElement(key, elem) {
      if (!elem) return;

      if (!this.innerUser[key]) {
        this.$set(this.innerUser, key, []);
      }

      this.$nextTick(() => {
        this.innerUser[key].push(elem);
      });
    },

    removeElement(key, elem) {
      const index = this.innerUser[key].indexOf(elem);
      this.innerUser[key].splice(index, 1);
    },
  },
};
</script>