<template>
  <div class="page-wrapper">
    <p class="title title--1">Equipe</p>

    <!-- <div class="page-wrapper__search">
      <p class="title title--2">Rechercher</p>
      <input type="text" v-model="name" placeholder="Par nom">
      <input type="text" v-model="email" placeholder="Par email">
    </div> -->

    <div class="page-wrapper__body">
      <div class="cards">
        <div v-for="(user, index) in users" class="card" :key="index">
          <div class="card__header">
            <p>{{ user.firstname }} {{ user.lastname }}</p>
            <p>{{ user.email }}</p>
          </div>

          <div class="card__action">
            <button @click="edit(user)">Editer</button>
          </div>
        </div>
      </div>
    </div>

    <Toaster />

    <Modal v-if="showModal" @close="close">
      <div slot="body">
        <FormUser 
          :user="user"
          @create="add"
          @save="update"
          @error="error"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import { Toaster, Modal } from '@/components/Utils';
import { mapGetters, mapActions } from 'vuex';
import { arrActions } from '@/mixins';
import FormUser from '@/components/Users/FormUser';

export default {
  components: { Toaster, Modal, FormUser },

  mixins: [ arrActions ],

  data() {
    return {
      user: {},
      showModal: false,
      name: '',
      email: '',
    };
  },

  created() {
    this.syncUsers();
  },

  computed: {
    ...mapGetters({
      users: 'users/getUsers',
    }),
  },

  methods: {
    ...mapActions({
      syncUsers: 'users/setUsersStore',
      addUser: 'users/createUser',
      updateUser: 'users/updateUser',
    }),

    newUser() {
      this.user = Object.assign({}, {
        firstname: '',
        lastname: '',
        tel: '',
        email: '',
        roles: ['ROLE_USER'],
      });

      this.$nextTick(() => {
        this.showModal = true;
      });
    },

    async add(user) {
      await this.addUser(user);

      this.close();
    },

    async update(user) {
      await this.updateUser(user);

      this.close();
    },

    close() {
      this.showModal = false;
    },

    edit(user) {
      this.user = {};

      this.user = Object.assign({}, user);

      this.$nextTick(() => {
        this.showModal = true;
      });
    },

    error(error) {
      this.setToaster({
        message: `Une erreur est survenue : ${error}`,
        type: 'error',
        time: Date.now(),
      });
    },
  },
};
</script>
